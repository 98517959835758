import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";

import { Reservation } from "../Images/Images";

export default function ReservationComp(props) {
    const { t } = useTranslation();
    return (
        <section className="reservation">
            <Reservation />
            <div
                onClick={props.showForm}
                role="button"
                onKeyDown={props.showForm}
                tabIndex={0}
            >
                <h2
                    dangerouslySetInnerHTML={{ __html: t("reservation.title") }}
                />
            </div>
            <button onClick={props.showForm}>{t("reservation.button")}</button>
        </section>
    );
}
