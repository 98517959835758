import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Arrow } from "../Images/Icons";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Reviews(props) {
    const [index, setIndex] = useState(0);

    const { reviews } = useStaticQuery(graphql`
        query {
            reviews: allFile(filter: { relativeDirectory: { eq: "reviews" } }) {
                edges {
                    node {
                        name
                        relativePath
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);
    const length = reviews.edges.length - 1;

    const handlePrevious = () =>
        index === 0 ? setIndex(length) : setIndex(index - 1);
    const handleNext = () =>
        index === length ? setIndex(0) : setIndex(index + 1);
    const { node } = reviews.edges[index];
    const indexTrue = index + 1;

    const { t } = useTranslation();
    return (
        <section className="reviews">
            <div className="reviews__container">
                <button
                    className="reviews__prevReview"
                    onClick={() => handlePrevious()}
                >
                    <Arrow />
                </button>
                <div className="reviews__review">
                    <GatsbyImage
                        image={node.childImageSharp.gatsbyImageData}
                        key={node.id}
                        alt={`Review ` + indexTrue}
                    />
                    <div
                        className="reviews__content"
                        dangerouslySetInnerHTML={{
                            __html: t("review.content` + indexTrue"),
                        }}
                    />
                </div>
                <button
                    className="reviews__nextReview"
                    onClick={() => handleNext()}
                >
                    <Arrow />
                </button>
            </div>
        </section>
    );
}
