import React, { Component, Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from "gatsby";

import { Arrow, Cross } from "../Images/Icons";

export default class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLightbox: false,
            selectedImage: null,
            index: 0,
        };
    }

    render() {
        const { selectedImage, showLightbox } = this.state;
        return (
            <StaticQuery
                query={graphql`
                    query {
                        gallery: allFile(
                            filter: { relativeDirectory: { eq: "gallery" } }
                        ) {
                            edges {
                                node {
                                    name
                                    relativePath
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                `}
                render={(data) => (
                    <Fragment>
                        {data.gallery.edges.map((image, index) => (
                            <div
                                key={index}
                                className="photos__button"
                                onClick={() =>
                                    this.setState({
                                        showLightbox: true,
                                        selectedImage: image,
                                        index: index,
                                    })
                                }
                                onKeyDown={() =>
                                    this.setState({
                                        showLightbox: true,
                                        selectedImage: image,
                                        index: index,
                                    })
                                }
                                tabIndex={0}
                                role="button"
                            >
                                <GatsbyImage
                                    image={
                                        image.node.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt="Gallery image"
                                />
                            </div>
                        ))}
                        {showLightbox && (
                            <div className="lightBox">
                                <div className="lightBox__inner">
                                    <div className="closeImgContainer">
                                        <button
                                            type="button"
                                            className="control control--close"
                                            onClick={() =>
                                                this.setState({
                                                    showLightbox: false,
                                                })
                                            }
                                        >
                                            <Cross />
                                        </button>
                                    </div>

                                    <GatsbyImage
                                        image={
                                            selectedImage.node.childImageSharp
                                                .gatsbyImageData
                                        }
                                        alt="Gallery image"
                                    />

                                    <button
                                        type="button"
                                        className="control control--prev"
                                        onClick={() => {
                                            if (this.state.index !== 0) {
                                                this.setState({
                                                    selectedImage:
                                                        data.gallery.edges[
                                                            this.state.index - 1
                                                        ],
                                                    index: this.state.index - 1,
                                                });
                                            } else if (this.state.index === 0) {
                                                this.setState({
                                                    selectedImage:
                                                        data.gallery.edges[
                                                            data.gallery.edges
                                                                .length - 1
                                                        ],
                                                    index:
                                                        data.gallery.edges
                                                            .length - 1,
                                                });
                                            }
                                        }}
                                    >
                                        <Arrow />
                                    </button>
                                    <button
                                        type="button"
                                        className="control control--next"
                                        onClick={() => {
                                            if (
                                                this.state.index <
                                                data.gallery.edges.length - 1
                                            ) {
                                                this.setState({
                                                    selectedImage:
                                                        data.gallery.edges[
                                                            this.state.index + 1
                                                        ],
                                                    index: this.state.index + 1,
                                                });
                                            } else if (
                                                this.state.index <
                                                data.gallery.edges.length
                                            ) {
                                                this.setState({
                                                    selectedImage:
                                                        data.gallery.edges[0],
                                                    index: 0,
                                                });
                                            }
                                        }}
                                    >
                                        <Arrow />
                                    </button>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            />
        );
    }
}
