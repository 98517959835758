import React from "react";
import Helmet from "react-helmet";

import Layout from "../layout";

import { Banner, Reviews } from "../components/Images/Images";

import Intro from "../components/Intro";
import About from "../components/About";
import Reservation from "../components/Reservation";

import Gallery from "../components/Gallery";
import ReviewsContainer from "../components/Reviews";
import Form from "../components/Form";

import { Eq1, Eq2, Eq3 } from "../components/Images/Icons";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import PromoVideoComponent from "../components/PromoVideo";

export default function Index() {
    const [showForm, setShowForm] = React.useState(false);
    React.useEffect(() => {
        if (showForm === true) {
            document.body.classList.add("modalOpened");
        } else {
            document.body.classList.remove("modalOpened");
        }
    });
    const { t } = useTranslation();
    return (
        <Layout showForm={() => setShowForm(!showForm)}>
            <Helmet>
                <title>{t("home.title")}</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Oswald:200,400,500,700&display=swap&subset=latin-ext"
                    rel="stylesheet"
                ></link>
            </Helmet>

            <Intro showForm={() => setShowForm(!showForm)} />
            <About />

            <section className="banner">
                <Banner />
                <h2 dangerouslySetInnerHTML={{ __html: t("banner.content") }} />
            </section>
            <PromoVideoComponent />
            <section className="gallery" id="gallery">
                <h2 dangerouslySetInnerHTML={{ __html: t("gallery.title") }} />

                <Gallery />
            </section>
            <Reservation showForm={() => setShowForm(!showForm)} />

            <section className="equipment">
                <div className="equipment__inner">
                    <div
                        style={{ gridColumn: "1 / -1" }}
                        dangerouslySetInnerHTML={{
                            __html: t("equipment.content"),
                        }}
                    />
                    <div className="equipment__block">
                        <Eq1 />

                        <h3
                            dangerouslySetInnerHTML={{
                                __html: t("equipment.title1"),
                            }}
                        />
                    </div>
                    <div className="equipment__block">
                        <Eq2 />
                        <h3
                            dangerouslySetInnerHTML={{
                                __html: t("equipment.title2"),
                            }}
                        />
                    </div>
                    <div className="equipment__block">
                        <Eq3 />
                        <h3
                            dangerouslySetInnerHTML={{
                                __html: t("equipment.title3"),
                            }}
                        />
                    </div>
                </div>
            </section>
            <section id="reviews" className="reviews">
                <Reviews />
                <ReviewsContainer />
            </section>
            <Form
                showFormState={showForm}
                showFormSwitch={() => setShowForm(!showForm)}
            />
        </Layout>
    );
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
