import React from "react";

import { Cross, InstaIcon, MessageHex, PromoVideo } from "../Images/Icons";
import video from "../../images/video.webm";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Intro(props) {
    const { t } = useTranslation();
    const [showVideo, setShowVideo] = React.useState(false);
    const iframeRef = React.useRef(null);
    return (
        <section className="intro">
            <div className="intro__video">
                <video playsInline muted autoPlay loop>
                    <source src={video} type="video/webm" />
                </video>
            </div>
            <div className="intro__content">
                <h1 dangerouslySetInnerHTML={{ __html: t("intro.title") }} />
                <div>
                    <button onClick={() => setShowVideo(true)}>
                        <PromoVideo name="promoVideo" />
                    </button>
                    <button onClick={props.showForm}>
                        <MessageHex name="messageHex" />
                    </button>
                    <a
                        href="https://www.instagram.com/galiabrothers/"
                        rel="noreferrer noopener"
                        target="_blank"
                    >
                        <InstaIcon name="messageHex" />
                    </a>
                </div>
            </div>

            <div
                className={`intro__promo-video  ${
                    showVideo ? "intro__promo-video--show" : ""
                }`}
            >
                <button onClick={() => setShowVideo(false)}>
                    <Cross />
                </button>
                <iframe
                    style={{ width: "100%", height: "90%" }}
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/bFUNY4QnuH4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    ref={iframeRef}
                />
            </div>
        </section>
    );
}
