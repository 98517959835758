import React from "react";

import { AboutImage } from "../Images/Images";
import { Arrow } from "../Images/Icons";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function About() {
    const [showMore, setShowMore] = React.useState(false);
    const { t } = useTranslation();
    return (
        <section className="about" id="about">
            <div style={{ textAlign: "center" }}>
                <AboutImage />
            </div>
            <div
                className={`about__content ${
                    showMore ? "" : "about__content--closed"
                }`}
                dangerouslySetInnerHTML={{ __html: t("about.content") }}
            />
            <button onClick={() => setShowMore(!showMore)}>
                <span>{t("about.readmore")}</span>
                <Arrow />
            </button>
        </section>
    );
}
