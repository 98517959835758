import React from "react";

export default function PromoVideoComponent() {
    return (
        <div style={{ aspectRatio: "16 / 9" }}>
            <iframe
                style={{ width: "100%", height: "100%" }}
                width="560"
                height="315"
                src="https://www.youtube.com/embed/bFUNY4QnuH4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            />
        </div>
    );
}
